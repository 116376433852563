body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Header Styles */
header {
  background-color: #333;
  color: #fff;
  padding: 10px;
  height: 12vh;
  list-style-type: none;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header h1 {
  font-size: 3.85vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

header #title-assess {
  font-size: 3.85vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

header h2 {
  font-size: 3.85vw;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

nav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#logo-link {
  text-decoration: none;
  width: 15vw;
}

.login-container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 26px; /* Adjust padding as needed */
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
}

.la, .latool {
  width: 50%; /* Adjust the width as needed */
  max-width: 66vh;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f9c74f; /* Adjust background color as needed */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  padding-top: 26px;
  padding-bottom: 26px;
  margin-top: 26px;
  margin-bottom: 20px; /* Adjust margin between .la and .latool */
}

.latool {
  width: 90%; /* Adjust the width as needed */
  max-width: 80vw;
  background-color: #fff3d8; /* Adjust background color as needed */
}

/*
.la {
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  max-width: 66vh;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f9c74f;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  padding-bottom: 26px;
  flex-direction: column;
  justify-content: center;
}

.login-container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 1rem;
  border-radius: 5px;
  padding-bottom: 26px;
  flex-direction: column;
  align-items: center;
}

.latool {
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 80vw;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff3d8;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  padding-bottom: 26px;
  flex-direction: column;
  justify-content: center;
}
*/

.options {
  display: flex;
  justify-content: center;
  padding-top: 3vh;
  padding-bottom: 3vh;
  gap: 20px;
  cursor: pointer;
}

.option {
  padding: 10px 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 18px;
  transition: all 0.3s ease;
}

.option:hover {
  background-color: #f0f0f0;
}

.selected {
  background-color: #f0f0f0;
  border-color: #007bff;
}

#lanext {
  margin-top: 3vh;
}

.movedown {
  margin-top: 6vh;
}

.makethisthinner {
  margin: 0 auto;
  margin-bottom: 5vh;
  max-width: 50vw;
}


/* Hero Section Styles */
.hero1 {
  background-color: #f9c74f;
  background-size: cover;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000000;
}

.hero1 h1 {
  font-size: 36px;
}

/* Hero Section Styles */
.hero {
  background-color: #f9c74f;
  background-size: cover;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000000;
}

.hero h1 {
  font-size: 20px; /* Adjust the multiplier as needed */
}

@media (min-width: 600px) {
  .hero h1 {
    font-size: 4vw; /* Minimum font size for larger screens */
  }
}

.hero p {
  font-size: 15px;
}

@media (min-width: 600px) {
  .hero p {
    font-size: 24px;
    margin-bottom: 30px;
  }
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
}

/* Courses Section Styles */

.courses {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 36px;
}

.courses h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.course {
  margin-bottom: 16px;
}

.course p {
  font-size: 12px;
  margin-bottom: 10px;
}

@media (min-width: 296px) {
  .courses {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 75px;
  }
}
@media (min-width: 600px) {
  .courses {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 50px;
  }
  
}
@media (min-width: 600px) {
  .courses h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
}
@media (min-width: 600px) {
  .course p {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
@media (min-width: 600px) {
  .course {
    margin-bottom: 30px;
  }
}

/* Footer Styles */
footer {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
}

.login-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: auto;
}

.hi {
  margin-bottom: 16px;
  margin-right: 16px;
  margin-left: 16px;

}

.right-align {
  display: flex;
  justify-content: space-between;
  align-items: center;  
  margin-right: 5vw;
}

.add-class {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.login-button-nohover {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: auto;
  margin-bottom: 3vh;
}

.login-button:hover {
  background-color: #45a049;
}

.login-button:active {
  background-color: #3e8e41;
}

.add-class-nohover {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.add-class:hover {
  background-color: #45a049;
}

.add-class:active {
  background-color: #3e8e41;
}

.la-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: 10px solid #fff3d8;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  margin: auto;
}

.la-button:hover {
  background-color: #45a049;
}

.la-button:active {
  background-color: #3e8e41;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
  font-size: 18px;
  text-align: center;
}

.Login main {
  width: 50%;
  max-width: 66vh;
  min-height: 38vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  margin: 0 auto;
  background-color: #f9c74f;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/*.register {
  text-align: center;
  position: relative;
  left: 27.1%;
  top: 27.1%;
  width: 50%;
  max-width: 66vh;
  min-height: 20vh;
  padding: 50px;
  margin: 50px;
  background-color: #f9c74f;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
*/

.register {
  text-align: center;
  width: 50%;
  max-width: 66vh;
  min-height: 20vh;
  padding: 50px;
  margin: 0 auto;
  background-color: #f9c74f;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.whitespace{
  height: 6vh;
}

.la-container {
  padding-top: 3vh;
  border-radius: 5vh;
  text-align: center;
  display: flex;
  padding-bottom: 6vh;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 30vw;
}

.la-container-long {
  padding-top: 3vh;
  border-radius: 5vh;
  text-align: center;
  display: flex;
  padding-bottom: 6vh;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 88vw;
}

.username-hi {
  display: inline-block;
}

input[type="text"], input[type="password"], input[type="email"], input[type="tel"] {
  width: 36%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 3px;
}

.login-container button {
  padding: 10px;
  background-color: #ff4747;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 60px;
  margin-right: 60px;
}

.register-title {
  text-align: center;
}

.offscreen {
  position: absolute;
  left: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.fancy-box {
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: auto;
  margin-bottom: 3vh;
  background-image: linear-gradient(135deg, #ff0101 0%, #ff9b9b 100%);
}

.two-buttons {
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 2vh;
}

/* Style for table content rows */
.row {
  border: 1px solid black;
}

/* Alternate row color */
.row.even {
  background-color: #f2f2f2;
}

/* Style for table cells */
.cell {
  text-align: center;
  padding: 8px;
  border: 1px solid black;
}

.cell_smaller {
  font-size: 2vh;
  text-align: center;
  padding-left: 1vw;
  padding-right: 1vw;
  border: 1px solid black;
}

.cell_smallers {
  font-size: 1.8vh;
  text-align: center;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  border: 1px solid black;
}

.centered {
  text-align: center;
  padding-bottom: 5vh;
}

.table-page {
  margin-left: 0.8vw;
}

.thinner-text {
  margin-top: -0.3vh;
  margin-bottom: -0.3vh;
}

/* Style for buttons */
.button {
  background-color: yellow;
  border: 1px solid black;
  padding: 6px 10px;
  cursor: pointer;
}

/* Style for password reset button */
.reset-button {
  background-color: yellow;
  border: 1px solid black;
  padding: 4px 8px;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #4CAF50;
  color: white;
  padding: 12px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  margin-right: 1vw;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
  right: 0; /* Move the menu to the right side */
  /* text-align: right; */
  margin-right: 1vw;
}

.dropdown-content a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

#studentSelect {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  background-color: #fff;
  color: #333;
}

#studentSelect:hover {
  border-color: #555;
}

#studentSelect:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.attendance-records {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  border: 5px solid transparent; /* Transparent border to make room for gradient */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Box Shadow for Depth */
  position: relative;
}

.home-squares {
  display: flex;
  justify-content: space-around;
  max-width: 96%; /* Adjust the container width as needed */
  margin: 0 auto;
}

.square {
  width: 250px;
  height: 250px;
  border-radius: 10px;
  background-color: #EFEFEF;
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: #000000;
}

.square:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.square1 {
  background-image: linear-gradient(135deg, #ff0101 0%, #ff9b9b 100%);
}

.square2 {
  background-image: linear-gradient(135deg, #0064f9 0%, #92c6ff 100%);
}

.square3 {
  background-image: linear-gradient(135deg, #ff9100 0%, #ffd798 100%);
}

.content {
  padding: 20px;
}

.home-squares-title {
  font-size: 2.5vw;
}

.home-squares-subtitle {
  font-size: 1.16vw;
}

.tab {
  text-align: center;
}